<!-- 查看转账信息 -->
<template>
    <div class="transferInfo center-wrap">
        <div class="page" v-if="info">
            <h1 v-text="$t('transfer.info')">转帐凭证</h1>
            <div class="text-box">
                <p class="text-title" v-text="$t('transfer.make_money')">收款信息</p>
                <div>
                    <div>
                        <h2 v-text="$t('transfer.name')">收款单位名称：</h2>
                        <p>{{info.company_name}}</p>
                    </div>
                    <div>
                        <h2 v-text="$t('transfer.bank')">收款单位及开户银行：</h2>
                        <p>{{info.bank_name}}</p>
                    </div>
                    <div>
                        <h2 v-text="$t('transfer.bank_num')">收款单位银行账户：</h2>
                        <p>{{info.bank_account}}</p>
                    </div>
                </div>
            </div>
            <div class="write-box">
                <p class="text-title" v-text="$t('transfer.information')">转行信息</p>
                <div>
                    <p v-text="$t('transfer.explain')">备注说明</p>
                    <textarea readonly v-model="info.remark"></textarea>
                </div>
            </div>
            <div v-for="item in info.image_arr">
                <el-link type="primary" v-if="item.indexOf('.pdf') >= 0" :href="item" target="_blank"> pdf文件 </el-link>
                <img :src="item" class="photo-box" v-else>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'TransferInfo',
    data() {
        return {
            info: null
        }
    },
    created() {
        this.getTransferInfo();
    },
    methods: {
        getTransferInfo: function() {
            var self = this;
            self.common.httpPost("/api/user/getTransferInfo", {
                order_code: self.$route.query.order_code
            }).then((ret) => {
                self.info = ret.data;
            })
        }
    },
    components: {}
}
</script>
<style scoped>
.page {
    margin-left: 283px;
    margin-right: 283px;
    padding-bottom: 50px;
}

h1 {
    font-size: 20px;
    color: #999999;
    line-height: 20px;
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
}

.text-box {
    width: 800px;
    border: 1px solid rgba(221, 221, 221, 0.7);
}

.text-title {
    padding-left: 30px;
    line-height: 50px;
    font-weight: 500;
    color: rgba(171, 171, 171, 1);
    background: #F7F6F6;
}

.text-box>div {
    margin: 26px 30px 29px 30px;
}

.text-box div div {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.text-box div div:last-child {
    margin-bottom: 0px;
}

h2 {
    width: 130px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    line-height: 12px;
}

.text-box div div p {
    font-size: 12px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 12px;
}

.write-box {
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 4px;
    border: 1px solid rgba(221, 221, 221, 0.7);
    overflow: hidden;
}

.write-box div {
    padding: 16px 30px;
}

.write-box div p {
    font-size: 14px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 16px;
    margin-bottom: 10px;
}

.write-box div textarea {
    height: 86px;
    width: 100%;
}

.photo-box {
    height: 416px;
    width: 800px;
}
</style>